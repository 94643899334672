<template>
  <div id="sortimentsverwaltung" key="sortimentsverwaltung">
    <v-container>
      <!-- heading -->
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Sortimentsverwaltung
          </h1>
        </v-col>
      </v-row>
      <!-- products -->
      <v-row v-if="productCategories.length > 0" class="mt-0 pt-0">
        <v-col cols="12" style="margin-top: -30px;">
          <!--:routing="routing"-->
          <ais-instant-search key="instantsearch" :cache="false" index-name="artikel" refresh="refreshClient"
                              :search-client="searchClient">
            <ais-configure
                :filters="activeFilter"
            >
            </ais-configure>
            <app-refresh :refreshsearch="refreshSearch" ref="refreshbtn"/>
            <div class="pt-0 mt-0">

              <div class="d-flex justify-lg-space-between"
                   style="position:sticky; width: 101%; top: 100px;z-index:2;padding-top: 3px;padding-bottom: 10px;background: #fff; border-bottom: 1px rgba(0, 0, 0, 0.12) solid;">

                <!-- product search -->
                <div class="flex-grow-1" style="max-width:400px;">
                  <ais-search-box>
                    <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                      <v-text-field
                          ref="srch"
                          clearable
                          class="mb-0"
                          placeholder="Suche nach Artikelname, Artikelnummer"
                          v-model="searchTerm"
                          @focus="refine($event.currentTarget.value)"
                          @keyup="refine($event.currentTarget.value);"
                      >
                      </v-text-field>
                    </template>
                  </ais-search-box>
                  <ais-stats>
                    <p slot-scope="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }" class="body-2 ma-0">
                      Total {{ nbHits }} Produkte gefunden
                    </p>
                  </ais-stats>
                </div>
                <!-- product add -->
                <div>
                  <v-btn
                      @click="addOwnProductModal = true; addOwnProductObject.updateOrNew = 'new'; addOwnProductObject = JSON.parse(JSON.stringify(addOwnProductTargetBase));"
                      depressed medium color="primary"
                      class="mb-0 mt-12 justify-end float-right">
                    Eigenes Produkt hinzufügen
                  </v-btn>

                  <v-btn
                      @click="toggleShowDeleted"
                      depressed medium :color="showDeleted ? 'primary' : 'black'" :outlined="!showDeleted"
                      class="mb-0 mt-12 justify-end float-right mr-4">
                    Gelöschte Produkte
                  </v-btn>
                </div>

              </div>

              <v-row>
                <!-- product filters -->
                <v-col cols="3">
                  <div class="pr-8" style="position: sticky !important; top: 230px">
                    <!-- filter row 1 -->
                    <div class="hide-v-massages">
                      <v-switch inset v-if="mySortiment" v-model="mySortiment.value" :true-value="adressNrAdr"
                                :false-value="false" label="mein Sortiment" dense small light flat class="mb-2">
                      </v-switch>
                      <v-switch inset v-if="migel" v-model="migel.value" label="nur MiGeL" dense :false-value="false"
                                medium light flat :true-value="1" class="mb-2"></v-switch>
                    </div>
                    <v-divider class="mt-4"></v-divider>
                    <ais-refinement-list attribute="klassifizierung" :sort-by="refinementSort"
                                         :key="'reflist_'+refinelistKey" :limit="20">
                      <div
                          slot-scope="{items,isShowingMore, isFromSearch, refine, createURL, searchForItems, sendEvent}">
                        <div v-if="items.length > 0">
                          <!-- filter row 1 -->
                          <div v-if="items.find(
                            item => item.value === 'Eigene Produkte'
                          )">
                            <div class="py-4 hide-v-massages">
                              <div v-for="item in items" :key="`filter-${item.label}`">
                                <div v-if="
                                  item.value === 'Eigene Produkte'
                                ">
                                  <v-checkbox v-if="!showDeleted" dense light @click.prevent="refine(item.value)">
                                    <template v-slot:label>
                                      <span class="body-1" style="font-weight:400 !important;">
                                        <span style="color:black;">{{ item.value }}</span> ({{
                                          item.count.toLocaleString()
                                        }})
                                      </span>
                                    </template>
                                  </v-checkbox>
                                </div>
                              </div>
                            </div>
                            <v-divider v-if="!showDeleted"></v-divider>
                          </div>
                          <!-- filter row 2 -->
                          <div class="pt-4 hide-v-massages">
                            <div v-for="item in items" :key="`filter-${item.label}`">
                              <div v-if="
                                item.value !== 'Eigene Produkte'
                              ">
                                <v-checkbox dense light @click.prevent="refine(item.value)">
                                  <template v-slot:label>
                                    <span class="body-1" style="font-weight:400 !important;">
                                      <span style="color:black;">{{ item.value }}</span> ({{
                                        item.count.toLocaleString()
                                      }})
                                    </span>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="items.length === 0">
                          <p class="my-4">
                            Keine Filter verfügbar
                          </p>
                        </div>
                      </div>
                    </ais-refinement-list>
                  </div>
                </v-col>
                <!-- product cards -->
                <v-col cols="9" class="mt-8">
                  <app-infinite-hits colwidth="3" key="ais-auto" class="pb-16" :key="'result_'+refinelistKey">
                    <template slot="item" slot-scope="{ item }">
                      <!-- product card -->
                      <v-card :ref="'card-'+item.objectID" class="mb-3 elevation-soft" rounded style="height:100%;">
                        <div class="d-flex flex-column justify-space-between" style="height:100%;">
                          <!-- product card content upper -->
                          <div>
                            <div class="d-flex justify-space-between align-top pa-4 text-sm-body-2">
                              <div class="green-tooltip top-0">
                                <!-- icon visible -->
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                                           viewBox="0 0 28 29">
                                        <circle @click="addToMySortiment(item.objectID)" id="Ellipse_356"
                                                data-name="Ellipse 356" cx="11.5" cy="11.5"
                                                r="11.5"
                                                :stroke="mySortimentIDs.indexOf(item.objectID) == -1 ? '#000' : '#fff'"
                                                :fill="mySortimentIDs.indexOf(item.objectID) == -1 ? '#fff' : '#CE20A8'"/>
                                      </svg>
                                    </div>
                                  </template>
                                  <span class="body-2" v-if="mySortimentIDs.indexOf(item.objectID) == -1">meinem Sortiment hinzufügen</span>
                                  <span class="body-2" v-else>meinem Sortiment entfernen</span>
                                </v-tooltip>
                              </div>
                              <div>
                                <div class="ma-0 cursor-pointer mb-4 pt-1"
                                     @click="openProductDetail(item.objectID)"
                                     style="border-bottom:1px solid black; text-decoration:none; color: #000; line-height:1;">
                                  Mehr zum Produkt
                                </div>
                                <span class="float-right" v-if="item.objectID.indexOf('SKU-') != -1">
                                  <v-img class="cursor-pointer"
                                         @click.stop="addOwnProductObject.updateOrNew = 'update';getOwnProdInfo(item.objectID)"
                                         :src="require('@/assets/img/ico/bearbeiten.svg')" width="20"/>
                                </span>
                              </div>
                            </div>
                            <div class="px-4 py-2">
                              <v-img v-if="item.hauptbild" :src="item.hauptbild" width="100%" height="140px"
                                     max-width="80px"
                                     max-height="140px" class="mx-auto" contain="contain"></v-img>
                              <div v-else style="min-height:140px;padding-top: 25px;">
                                <img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" width="100%"
                                     height="75px" max-width="80px"
                                     max-height="140px" class="mx-auto" contain="contain">
                              </div>
                            </div>
                            <div class="pa-4">
                              <p class="ma-0  font-weight-bold text-sm-body-2">
                                <span v-if="prodInfos[item.objectID + '-' + 'customerartno']">
                                  {{ prodInfos[item.objectID + '-' + 'customerartno'].value }}
                                </span>
                                <span v-else>
                                  {{ item.objectID }}
                                </span>
                              </p>
                              <div class="ma-0">
                                {{ item.name }}
                                <div v-if="recentButDeletedProducts.indexOf(item.objectID) > -1 "
                                     style="margin-top: 4px;display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 5px;font-weight: bold">
                                  <v-tooltip top class="body-2 py-1 px-2 ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-hover v-slot="{ hover }" open-delay="0">
                                        <v-icon color="red" v-bind="attrs" size="22" v-on="on"
                                                style="margin-top: -2px;">
                                          mdi-information-outline
                                        </v-icon>
                                      </v-hover>
                                    </template>
                                    <div class="py-2 pl-0">
                                      Dieses Produkt wurde aus dem Sortiment gelöscht
                                    </div>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- product card content lower -->
                          <div class="d-flex justify-lg-space-between align-end px-4 pb-4">
                            <div class="mr-2">
                              <v-btn
                                  width="165px"
                                  @click="curProdName = item.objectID+' - '+item.name; clickedProdID = item.objectID; ownProdNo = prodInfos[item.objectID+'-'+'ownartno'] ? prodInfos[item.objectID+'-'+'ownartno'].value : '';  addProductInfoModal = true;"
                                  outlined color="black" class="small-button text-left btn-erfassen"
                                  style="height: 25px !important; overflow: hidden;">
                                <span
                                    v-if="prodInfos[item.objectID+'-'+'ownartno'] && prodInfos[item.objectID+'-'+'ownartno'].value != ''">
                                  {{ prodInfos[item.objectID + '-' + 'ownartno'].value }}
                                </span>
                                <span v-else>
                                  LeistungsNr.<span class="erfassen"> erfassen</span>
                                </span>
                              </v-btn>
                            </div>

                            <div v-if="item.objectID.indexOf('SKU-') != -1"
                                 class="d-flex justify-lg-space-between align-end">
                              <v-hover v-if="item.isClientArticleActive !== 0" v-slot="{ hover }" open-delay="0">
                                <v-img @click="deleteOwnProd(item.objectID);" class="mt-0 cursor-pointer"
                                       :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                       alt="" max-width="30"></v-img>
                              </v-hover>
                              <v-hover v-else v-slot="{ hover }" open-delay="0">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                      <v-img @click="restoreOwnProduct(item.objectID)" class="mt-0 cursor-pointer"
                                             :src="hover ? require('@/assets/img/ico/neu-laden-aktiv.svg') : require('@/assets/img/ico/neu-laden.svg')"
                                             alt="" max-width="30"></v-img>
                                    </div>
                                  </template>
                                  <span>
                                    wieder aktiveren
                                  </span>
                                </v-tooltip>
                              </v-hover>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </template>
                  </app-infinite-hits>
                </v-col>
              </v-row>
            </div>
          </ais-instant-search>
        </v-col>
        <br><br>
      </v-row>
    </v-container>
    <v-dialog v-model="addOwnProductModal" max-width="600">
      <v-card class="6">
        <v-card-title class="headline text-h5 font-weight-regular mb-4">
          Eigenes Produkt hinzufügen / bearbeiten
        </v-card-title>
        <v-card-text class="body-1">
          <v-row class="mb-4">
            <v-col cols="2" class="pr-0 mr-0 pt-7" style="max-width:60px !important;">
              <div v-if="addOwnProductObject.bild" style="position: relative;">
                <v-img :src="addOwnProductObject.bild"/>
                <v-img :src="require('@/assets/img/ico/plus-black.svg')" class="remove-image" @click="removeImage"/>
              </div>
              <v-img :src="require('@/assets/img/ico/bild-hinzufuegen.svg')" v-else/>
            </v-col>
            <v-col cols="4" class="caption font-weight-bold pt-8 pl-6" style="font-weight:bold !important;">
              <v-btn
                  style="padding: 0;"
                  plain
                  :loading="isSelectingFile"
                  @click="onUploadClick"
              >Produktbild<br>hinzufügen
              </v-btn>
              <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/jpeg, image/png"
                  @change="uploadImage"
              >
            </v-col>
            <!--<v-col cols="6">
              <v-file-input
                  placeholder="max 4MB jpg,png"
                  chips
                  show-size
                  small-chips
                  truncate-length="21"
              ></v-file-input>
            </v-col>-->
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-text-field maxlength="15" :disabled="addOwnProductObject.updateOrNew == 'update' ? true : false"
                            clearable v-model="addOwnProductObject.customerartno" label="Artikelnummer*"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-text-field maxlength="50" clearable v-model="addOwnProductObject.name"
                            label="Artikelname*"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-textarea rows="2" clearable maxlength="100" v-model="addOwnProductObject.txt"
                          label="Artikel Langtext"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-text-field clearable v-model="addOwnProductObject.ownartno" label="Leistungsnummer"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-select
                  v-model="addOwnProductObject.verpackungseinheit"
                  :items="vpEinheit"
                  label="Einheit"
                  item-text="name"
                  item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <vuetify-money class="mb-0 pb-0" :options="{locale:'de-CH',precision:2,prefix: 'CHF'}" clearable
                             type="number"
                             v-model="addOwnProductObject.preis"
                             :label="'Preis inkl. MwSt. und Admin-Zuschlag pro '+veString "></vuetify-money>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-0 my-0">
              <v-switch v-model="addOwnProductObject.migelactive"
                        label="MiGeL"
                        :true-value="1" :false-value="0" dense small light flat>
              </v-switch>
            </v-col>
            <v-col class="py-0 my-0" v-if="addOwnProductObject.migelactive">
              <v-text-field clearable v-mask="'##.##.##.##.#'" v-model="addOwnProductObject.migel"
                            label="Nummer"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0 my-0 mt-4 mb-4">
              <span @click="showOwnProdMoreInfo= showOwnProdMoreInfo ? false : true"
                    class="text-decoration-underline cursor-pointer font-weight-bold">
                {{ showOwnProdMoreInfo ? 'Optionale Angaben ausblenden' : 'Optionale Angaben einblenden' }}
              </span>
            </v-col>
          </v-row>

          <v-row v-if="showOwnProdMoreInfo">
            <v-col class="py-0 my-0">
              <v-text-field clearable type="number" v-model="addOwnProductObject.gtin" label="GTIN"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showOwnProdMoreInfo">
            <v-col class="py-0 my-0">
              <v-text-field clearable type="number" v-model="addOwnProductObject.pharmacode"
                            label="Pharmacode"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showOwnProdMoreInfo">
            <v-col class="py-0 my-0">
              <v-text-field clearable type="number" v-model="addOwnProductObject.umverpackungstk"
                            :label="'Einkaufsverpackung enthält (Anzahl '+veString+' )'"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showOwnProdMoreInfo">
            <v-col class="py-0 my-0">
              <v-select
                  clearable
                  v-model="addOwnProductObject.umverpackung"
                  :items="uvpEinheit"
                  label="Einkaufsverpackung"
                  item-text="name"
                  item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
              v-if="addOwnProductObject.umverpackung  && addOwnProductObject.umverpackungstk != '' && addOwnProductObject.verpackungseinheit">
            <v-col class="py-0 my-0">
              <i style="font-size:13px;margin-top: -10px;display:block">{{ uvString }} à
                {{ addOwnProductObject.umverpackungstk }} {{ veString }}</i>
            </v-col>
          </v-row>
          <v-row v-if="showOwnProdMoreInfo">
            <v-col class="py-0 my-0">
              <v-text-field clearable maxlength="40" v-model="addOwnProductObject.lieferant"
                            label="Lieferant"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="addOwnProductModal = false;">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="saveOwnProduct()">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addProductInfoModal" persistent max-width="490">
      <product-lp-type-hint
          :key="'hint'+curProdName"
          :curProdNameProp="curProdName"
          :curProdId="clickedProdID"
          :ownProdNoProp="ownProdNo"
          :showRemove="true"
          @addProductInfo="addProductInfo"
          @abort="addProductInfoModal = false"
      />
    </v-dialog>
    <v-dialog v-model="productDetailModal" max-width="320">
      <product-detail-card @closeProdInfo="productDetailModal = false" @addToMySortiment="addToMySortiment"
                           :info="productDetail" :mySortimentIDs="mySortimentIDs"></product-detail-card>
    </v-dialog>
  </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import httpClient from '../utils/httpClient'
import 'instantsearch.css/themes/satellite-min.css'
import AppRefresh from '../components/search/Refresh'
import { history } from 'instantsearch.js/es/lib/routers'
import { simple } from 'instantsearch.js/es/lib/stateMappings'
import AppInfiniteHits from '../components/search/InfiniteHits'
import ProductDetailCard from '../components/products/ProductDetailCard'
import ProductLpTypeHint from '../components/products/ProductLpTypeHint'

export default {
  name: 'Sortimentsverwaltung',
  components: {
    ProductLpTypeHint,
    AppInfiniteHits,
    AisInstantSearch,
    AisSearchBox,
    AppRefresh,
    ProductDetailCard
  },
  data () {
    return {
      refreshClient: false,
      adressNrAdr: this.$store.state.benutzer.info.AdressNrADR,
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      addOwnProductTargetBase: [],
      addOwnProductObject: {
        name: '',
        ownartno: '',
        verpackungseinheit: 'ea',
        umverpackung: '',
        umverpackungstk: '',
        customerartno: '',
        txt: '',
        preis: 0.00,
        migelactive: 0,
        migel: '',
        updateOrNew: 'new',
      },
      showDeleted: false,
      vpEinheit: [],
      uvpEinheit: [],
      refinelistKey: 0,
      showOwnProdMoreInfo: false,
      refreshSearch: 0,
      productCategories: [],
      searchTerm: '',
      ownProdNo: '',
      prodInfos: [],
      recentButDeletedProducts: [],
      mySortimentIDs: [],
      mySortimentIDFilter: '',
      curProdName: '',
      clickedProdID: 0,
      productDetail: [],
      addProductInfoModal: false,
      addOwnProductModal: false,
      productDetailModal: false,
      addProdToModal: false,
      filterTypes: [
        {
          'name': 'MiGel',
          'value': false,
          'filterType': 'filter',
          'operator': '=',
          'key': 'isArticleMiGeL'
        },
        {
          'name': 'mySortiment',
          'value': false,
          'filterType': 'mysortiment',
        }
      ],
      searchClient: algoliasearch(
          process.env.VUE_APP_ALGOLIA_ID,
          process.env.VUE_APP_ALGOLIA_KEY,
      ),

      isSelectingFile: false
    }
  },

  methods: {

    toggleShowDeleted () {
      this.showDeleted = !this.showDeleted
    },

    refinementSort: function (a, b) {
      return this.productCategories.indexOf(a.name) - this.productCategories.indexOf(b.name)
    },

    log (data) {
      console.log(data)
    },

    timeout: function timeout (ms) {
      return new Promise(function (resolve) {
        return setTimeout(resolve, ms)
      })
    },

    async getOwnProdInfo (objectID) {
      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'get/ownproduct-info',
        method: 'post',
        data: { objectID: objectID }
      })

      if (res.type == 'success') {
        this.addOwnProductObject = res.ownProdInfos
        this.addOwnProductModal = true
      }
    },

    async openProductDetail (prodID) {

      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/product-detail',
        method: 'post',
        data: { prodID: prodID }
      })

      if (res.type == 'success') {
        this.productDetail = res.detail
        this.productDetailModal = true
      }
    },

    async restoreOwnProduct (objectID) {
      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'restore/ownproduct',
        method: 'post',
        data: { objectID: objectID }
      })
      if (res.type == 'success') {

        setTimeout(function () {
          window.location.href = window.location.href

        }, 2000)

      }
    },

    async deleteOwnProd (objectID) {

      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'delete/ownproduct',
        method: 'post',
        data: { objectID: objectID }
      })

      if (res.confirm) {

        var msg = 'Dieses Produkt tatsächlich löschen? Es werden keine Zähldaten gelöscht, Dieses Produkt wird jedoch aktuell von <b class=" font-weight-bold">' + res.confirm.length + '</b> Bewohner verwendet'

        this.$confirm(msg, {
          title: 'Achtung',
          buttonTrueText: 'Löschen',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {

            const res = await httpClient({
              url: process.env.VUE_APP_API_SHARED + 'delete/ownproduct',
              method: 'post',
              data: { objectID: objectID, confirmed: true }
            })

            if (res.type == 'success') {
              setTimeout(function () {
                window.location.href = window.location.href

              }, 2000)
            }
          }
        })

      } else {
        if (res.type == 'success') {

          setTimeout(function () {
            window.location.href = window.location.href

          }, 2000)

        }
      }
    },

    async refreshSearchInstance () {
      var self = this
      const resProdInfo = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'get/prodinfo',
        method: 'get'
      })

      if (resProdInfo.type == 'success') {
        this.prodInfos = resProdInfo.infos
      }

      this.searchClient.clearCache()

      setTimeout(function () {
        self.refinelistKey++
      }, 1800)

    },
    async addToMySortiment (objectID) {

      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'save/mein-sortiment',
        method: 'post',
        data: { artnr: objectID }
      })

      if (res.confirm) {

        if (res.confirm.length === 1) {
          var msg = 'Möchten Sie dieses Produkt aus Ihrem Sortiment entfernen? Es werden KEINE Daten gelöscht, jedoch gibt es <b class=" font-weight-bold">' + res.confirm.length + '</b> Bewohner welcher dieses Produkt nutzt.'
        } else {
          var msg = 'Möchten Sie dieses Produkt aus Ihrem Sortiment entfernen? Es werden KEINE Daten gelöscht, jedoch gibt es <b class=" font-weight-bold">' + res.confirm.length + '</b> Bewohner welche dieses Produkt nutzen.'
        }
        this.$confirm(msg, {
          title: 'Achtung',
          buttonTrueText: 'Entfernen',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {

            const res = await httpClient({
              url: process.env.VUE_APP_API_SHARED + 'save/mein-sortiment',
              method: 'post',
              data: { artnr: objectID, confirmed: true }
            })

            this.searchClient.clearCache()

            if (res.type == 'success') {
              this.mySortimentIDs = res.mysortiment
              this.mySortimentIDFilter = res.mysortimentFilter
            }
          }
        })

      } else {
        this.searchClient.clearCache()

        if (res.type == 'success') {
          this.mySortimentIDs = res.mysortiment
          this.mySortimentIDFilter = res.mysortimentFilter
        }
      }

      if (res.type == 'success') {

        setTimeout(function () {
          window.location.href = window.location.href

        }, 2000)

      }
    },

    async addProductInfo (optionsObj) {
      this.addProductInfoModal = false
      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'save/prodinfo',
        method: 'post',
        data: { artnr: this.clickedProdID, key: 'ownartno', value: optionsObj.ownProdNo }
      })

      this.refreshSearchInstance()

      if (res.type == 'success') {
        this.addProductInfoModal = false
        this.prodInfos = res.infos
        this.ownProdNo = ''
        this.addOwnProductObject = JSON.parse(JSON.stringify(this.addOwnProductTargetBase))
      }
    },

    async saveOwnProduct () {
      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'save/ownproduct',
        method: 'post',
        data: this.addOwnProductObject
      })

      if (res.type == 'success') {
        this.addOwnProductObject = JSON.parse(JSON.stringify(this.addOwnProductTargetBase))

        this.addOwnProductModal = false
        const resSortiment = await httpClient({
          url: process.env.VUE_APP_API_SHARED + 'get/mein-sortiment',
          method: 'get'
        })

        if (resSortiment.type == 'success') {
          this.mySortimentIDs = resSortiment.mysortiment
          this.mySortimentIDFilter = resSortiment.mysortimentFilter
        }

        this.refreshSearchInstance()
      }
    },

    onUploadClick() {
      this.isSelectingFile = true
      window.addEventListener('focus', () => {
        this.isSelectingFile = false
      }, {once: true})

      this.$refs.uploader.click()
    },

    async uploadImage(e) {
      let file = e.target.files[0]

      let data = new FormData();
      data.append('product_image', file, file.name);
      data.append('product_id', this.addOwnProductObject.artnr);

      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'ownproduct/image',
        method: 'post',
        headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
        data: data
      }).then((response) => {
        this.isSelectingFile = false
        this.addOwnProductObject.bild = response.img
        this.refreshSearchInstance();
      }).catch(() => {
        this.isSelectingFile = false
      });
    },

    async removeImage(e) {
      await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'ownproduct/image/' + this.addOwnProductObject.artnr,
        method: 'delete',
      }).then(() => {
        this.isSelectingFile = false
        this.addOwnProductObject.bild = null
        this.refreshSearchInstance();
      });
    }
  },

  watch: {
    searchTerm(val) {
      if (!val) {
        this.searchTerm = '';
        var self = this;
        self.$refs.srch.blur();
        setTimeout(function () {
          self.$refs.srch.focus();
        }, 100)
      }
    }
  },

  computed: {

    uvString() {
      var self = this;
      let uvp = this.uvpEinheit.filter(function (el, k) {
        if (el.value == self.addOwnProductObject.umverpackung) {
          return el.name;
        }
      })
      if (uvp.length > 0) {
        return uvp[0].name;
      } else {
        return '';
      }

    },

    veString() {
      var self = this;
      let uvp = this.vpEinheit.filter(function (el, k) {
        if (el.value == self.addOwnProductObject.verpackungseinheit) {
          return el.name;
        }
      })
      if (uvp.length > 0) {
        return uvp[0].name;
      } else {
        return '';
      }

    },

    //Filter Switch
    migel() {
      let migel = this.filterTypes.filter(function (el, k) {
        if (el.name == 'MiGel') {
          return el;
        }
      })
      return migel[0];
    },

    //Filter Switch
    mySortiment() {
      let mySortiment = this.filterTypes.filter(function (el, k) {
        if (el.name == 'mySortiment') {
          return el;
        }
      })
      return mySortiment[0];
    },

    activeFilter() {
      var outputFilter = '(isClient:false OR isClient:' + this.adressNrAdr + ') AND ((geloscht:0 OR NOT isClient:false)  ';

      var hasRecentDeleted = false;
      this.recentButDeletedProducts.forEach(function (artnr, k) {
        hasRecentDeleted = true;
        if (k === 0) {
          outputFilter += ' OR (objectID:' + artnr;
        } else {
          outputFilter += ' OR objectID:' + artnr;
        }
      })

      if (hasRecentDeleted) {
        outputFilter += ')';
      }

      outputFilter += ')';

      var self = this;
      this.filterTypes.map(function (el, k) {

        if (el.value && outputFilter != '') {
          outputFilter += ' AND ';
        }

        if (el.filterType != 'mysortiment') {
          if (el.value) {
            outputFilter += el.key + el.operator + el.value; //+ ' AND objectID:1351046'
          }
        } else {
          if (el.value) {
            outputFilter += '(';
            outputFilter += self.mySortimentIDFilter
            outputFilter += ')';
          }
        }
      })
      console.log(outputFilter)
      return outputFilter;
    },
  },

  async mounted() {

    this.addOwnProductTargetBase = JSON.parse(JSON.stringify(this.addOwnProductObject));

    const resRecent = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/recent-deleted-but-history-products',
      method: 'get'
    })

    if (resRecent.type == 'success') {
      this.recentButDeletedProducts = resRecent.products;
    }

    const res = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/mein-sortiment',
      method: 'get'
    })

    if (res.type == 'success') {
      this.mySortimentIDs = res.mysortiment;
      this.mySortimentIDFilter = res.mysortimentFilter;
    }

    const resProdInfo = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/prodinfo',
      method: 'get'
    })

    if (resProdInfo.type == 'success') {
      this.prodInfos = resProdInfo.infos;
    }

    const resCategories = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/categories-sort-array',
      method: 'get'
    })

    if (resCategories.type == 'success') {
      this.productCategories = resCategories.categories;
    }

    const resGlobaInfo = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/get-global-infos',
      method: 'get'
    })

    if (resGlobaInfo.type == 'success') {
      this.uvpEinheit = resGlobaInfo.uvpUnits;
      this.vpEinheit = resGlobaInfo.vpUnits;
    }
  },
}
</script>

<style lang="scss">
@import "./src/scss/sortimentsverwaltung";
</style>
