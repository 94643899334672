<template>
  <div class="bewohner-detail-meta">
    <div v-if="!edit">
      <div class="meta-row">
        <div>
          <div><b class="data-label">Strasse</b></div>
          <div>{{ cart.strasse }} {{ cart.hausnr }}</div>
        </div>

        <div>
          <div><b class="data-label">Ort</b></div>
          <div>{{ cart.PLZ }} {{ cart.ort }}</div>
        </div>

        <div>
          <div><b class="data-label">Geburtsdatum</b></div>
          <div>{{ (cart.birthdate && $moment(cart.birthdate).isValid()) ? $moment(cart.birthdate).format('DD.MM.YYYY') : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Eintrittsdatum</b></div>
          <div>{{ cart.eintritt ? $moment(cart.eintritt).format('DD.MM.YYYY') : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Austrittsdatum</b></div>
          <div>{{ cart.austritt ? $moment(cart.austritt).format('DD.MM.YYYY') : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Zuweisender Arzt</b></div>
          <div>{{ cart.arzt ? cart.arzt.name : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Krankenkasse</b></div>
          <div>{{ cart.krankenkasse ? cart.krankenkasse.Versicherer_Name : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Versichertennummer</b></div>
          <div>{{ cart.versichertennummer ? cart.versichertennummer : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">AHV-Nummer</b></div>
          <div>{{ cart.ahv ? cart.ahv : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Versichertenkarte-Nr.</b></div>
          <div>{{ cart.veka ? cart.veka : '–' }}</div>
        </div>

        <div>
          <div><b class="data-label">Ablaufdatum</b></div>
          <div>{{ cart.ablauf_karte ? $moment(cart.ablauf_karte).format('DD.MM.YYYY') : '–' }}</div>
        </div>
      </div>

      <div class="editbtn">
        <v-btn
            icon
            color="primary"
            @click="openEditForm"
            v-if="cart.status === 'prepared'"
        >
          <v-hover v-slot="{ hover }" open-delay="0">
            <span style="display: inline-flex" class="px-1">
              <v-img v-if="hover" class="mt-0 cursor-pointer"
                     :src="require('@/assets/img/ico/bearbeiten-aktiv.svg')" alt=""
                     max-width="30"></v-img>
              <v-img v-else class="mt-0 cursor-pointer"
                     :src="require('@/assets/img/ico/bearbeiten.svg')" alt="" max-width="30"></v-img>
            </span>
          </v-hover>
        </v-btn>
      </div>
    </div>
    <div v-else-if="bewohnerData">
      <div class="meta-row">
        <div>
          <v-text-field v-model="bewohnerData.strasse" label="Strasse"/>
        </div>
        <div>
          <v-text-field v-model="bewohnerData.hausnr" label="HausNr"/>
        </div>
        <div>
          <v-text-field v-model="bewohnerData.PLZ" label="PLZ"/>
        </div>
        <div>
          <v-text-field v-model="bewohnerData.ort" label="Ort"/>
        </div>
        <div>
          <FieldDatePicker
              v-model="bewohnerData.birthdate"
              label="Geburtsdatum"
              :max="new Date().toISOString().slice(0, 10)"
          />
        </div>
        <div>

        </div>
        <div>
          <FieldDatePicker
              v-model="bewohnerData.eintritt"
              label="Eintrittsdatum"
              :max="bewohnerData.austritt ? $moment(bewohnerData.austritt).subtract(1,'day').format('YYYY-MM-DD'): null"

          />
        </div>
        <div>
          <FieldDatePicker
              v-model="bewohnerData.austritt"
              label="Austrittsdatum"
              :min="bewohnerData.eintritt ? $moment(bewohnerData.eintritt).add(1,'day').format('YYYY-MM-DD') : null"
          />
        </div>
        <div>
          <v-text-field v-model="bewohnerData.versichertennummer" label="Versichertennummer"/>
        </div>
        <div>
          <v-text-field v-model="bewohnerData.ahv" label="AHV-Nummer"/>
        </div>
        <div>
          <v-text-field v-model="bewohnerData.veka" label="Versichertenkarte-Nr."/>
        </div>
        <div>
          <FieldDatePicker
              v-model="bewohnerData.ablauf_karte"
              label="Ablaufdatum Versichertenkarten-Nr."
          />
        </div>

        <div style="grid-column: span 2">
          <ArztSelectDialog
              :bewohner='bewohnerData'
              :selected='bewohnerData.aerzte_id'
              @choose="setArzt"
          >
            <template #button="slotProps">
              <v-autocomplete
                  label="Zuweisender Arzt"
                  class="custom-disabled overflow-select"
                  v-model="bewohnerData.aerzte_id"
                  :items="aerzte"
                  :readonly="true"
                  clearable
                  clear-icon="mdi-close"
                  item-text="name"
                  item-value="ID"
                  variant="underlined"
                  @click="slotProps.open()"
              >
                <template v-slot:selection="data">
                  {{ data.item.name + ', ' + data.item.ort }}
                </template>
                <template v-slot:append>
                  <v-icon
                      @click="slotProps.open()"
                  >mdi-magnify
                  </v-icon>
                </template>
              </v-autocomplete>
            </template>
          </ArztSelectDialog>
        </div>
        <div style="grid-column: span 2">
          <KrankenkasseSelectDialog
              :bewohner='bewohnerData'
              :selected='bewohnerData.krankenkasse_id'
              @choose="setKrankenkasse"
          >
            <template #button="slotProps">
              <v-autocomplete
                  label="Krankenkasse"
                  class="custom-disabled overflow-select"
                  v-model="bewohnerData.krankenkasse_id"
                  :items="krankenkassen"
                  :readonly="true"
                  clearable
                  clear-icon="mdi-close"
                  append-icon=""
                  item-text="Versicherer_Name"
                  item-value="ID"
                  @click="slotProps.open()"
                  variant="underlined"
              >
                <template v-slot:selection="data">
                  {{ data.item.Versicherer_Name + ', ' + data.item.Ort }}
                </template>
                <template v-slot:append>
                  <v-icon
                      @click="slotProps.open()"
                  >mdi-magnify
                  </v-icon>
                </template>
              </v-autocomplete>
            </template>
          </KrankenkasseSelectDialog>
        </div>
      </div>

      <div class="text-right">
        <v-btn
            @click="cancelEditForm"
            depressed
           class="cancel-outline"
        >
          zurücksetzen
        </v-btn>
      </div>
    </div>

    <div>
      <div>
        <v-textarea
            :disabled="cartHasBeenSent"
            v-model="cart.comment"
            background-color="white"
            outlined
            no-resize
            rows="2"
            counter="350"
            placeholder="Bemerkung"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldDatePicker from "@/components/form/FieldDatePicker.vue";
import KrankenkasseSelectDialog from "@/components/Bewohner/Bewohnerverwaltung/dialogs/KrankenkasseSelectDialog.vue";
import {aerzteActions, aerzteGetters} from "@/components/observables/aerzte";
import {krankenkassenActions, krankenkassenGetters} from "@/components/observables/krankenkassen";
import ArztSelectDialog from "@/components/Bewohner/Bewohnerverwaltung/dialogs/ArztSelectDialog.vue";

export default {
  name: "BewohnerDetailMeta",
  components: {ArztSelectDialog, KrankenkasseSelectDialog, FieldDatePicker},
  props: {
    cart: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      edit: false,
      showEintrittsdatum: false,
      showAustrittsdatum: false,
      bewohnerDataKeys: ['strasse', 'hausnr', 'PLZ', 'ort', 'birthdate', 'eintritt', 'austritt', 'aerzte_id', 'krankenkasse_id', 'versichertennummer', 'ahv', 'veka', 'ablauf_karte'],
      bewohnerData: null
    }
  },

  computed: {
    ...aerzteGetters,
    ...krankenkassenGetters,
    cartHasBeenSent() {
      return this.cart.status !== 'prepared'
    }
  },

  methods: {
    openEditForm() {
      this.edit = true
    },

    cancelEditForm() {
      this.initBewohnerData()
      this.edit = false
    },

    initBewohnerData() {
      const data = {}

      this.bewohnerDataKeys.forEach(key => {
        data[key] = this.cart[key]
      })

      // this is necessary to keep the object reactive
      this.bewohnerData = Object.assign({}, data)
    },

    setKrankenkasse(krankenkassenId) {
      this.bewohnerData.krankenkasse_id = +krankenkassenId
    },

    setArzt(arztId) {
      this.bewohnerData.aerzte_id = +arztId
    }
  },

  watch: {
    bewohnerData: {
      deep: true,
      handler() {
        if (this.edit) {
          this.$emit('update', this.bewohnerData)
        }
      }
    },

    edit() {
      if (!this.edit) {
        this.$emit('update', null)
      }
    }
  },

  created() {
    this.initBewohnerData()
    krankenkassenActions.fetchKrankenkassen(this.bewohnerData.id)
    aerzteActions.fetchAerzte(this.bewohnerData.id)
  }
}
</script>
<style lang="scss">
.bewohner-detail-meta {
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 20px;

  .editbtn{
    position: absolute;
    bottom: 140px;
    right: 0;
  }

  .v-textarea fieldset {
    border: none;
  }

  .v-select__selections {
    height: 32px;
    overflow: hidden;
  }
}

.meta-row {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  row-gap: 30px;

  .data-label {
    font-size: 12px;
    font-weight: 300;
  }
}

.cancel-outline{
  border: #241911 solid 1px;
  background-color: transparent !important;
}

.meta-edit-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 0;
}

</style>