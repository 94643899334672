import Vue from 'vue'
import Vuex from 'vuex'
import { system } from './modules/system'
import { loader } from './modules/loader';
import { benutzer } from './modules/benutzer';
import { bilder } from './modules/bilder';
import { direktabrechnung } from './modules/direktabrechnung';
import {prescription} from '@/store/modules/prescription'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['benutzer']
  })],
  state: {
  },
  modules: {
    system,
    loader,
    benutzer,
    bilder,
    direktabrechnung,
    prescription
  }
});