<template>
  <div id="superadmin">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Einstellungen
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2>Adresse</h2>
          <v-data-table class="custom-data-table custom-padding"
                        :hide-default-footer="true"
                        :headers="addressHeaders"
                        :items="addresses">
            <template v-slot:item.Logo="{ item }">
              <LogoDialog :logo="info.logo">
                <template #button="slotProps">
                  <v-hover v-slot="{hover}">
                    <template v-if="!info.logo">
                      <v-img
                          :src="hover ? require('@/assets/img/ico2023/upload-hover.svg') : require('@/assets/img/ico2023/upload.svg')"
                          :contain="true" alt="" class="icon" max-height="20"
                          @click="slotProps.open()"></v-img>
                    </template>
                    <template v-else>
                      <a @click="slotProps.open()" class="logo-filename">{{ logoDisplayName }}</a>
                    </template>
                  </v-hover>
                </template>
              </LogoDialog>
            </template>
          </v-data-table>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="custom-box">
            <div class="title">
              <h2>Integration</h2>
            </div>
            <div class="content">
              <v-switch v-model="inkosync" :true-value="1" :false-value="0"
                        label="Inkoplaner-Synchronisation"></v-switch>
            </div>
            <div class="footer">
              <strong>Achtung: </strong>Wenn die Synchronisation aktiviert wird, werden alle
              Inko-Planungsartikel den Bewohnern im Verbrauchsmanager zugewiesen.
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isPurasdirectActive">
        <v-col cols="12">
          <div class="custom-box">
            <div class="title">
              <h2>Puras Direkt</h2>
            </div>
            <div class="content">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-switch v-model="isPurasDirectLive" :true-value="1" :false-value="0"
                            label="Direktabrechnung live schalten"></v-switch>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <h4>Admin Zuschlag Abrechnung MiGeL Produkte (in %)</h4>
                  <v-text-field suffix="%" v-model="zuschlag" placeholder="0"
                                style="width: 200px"></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <h4>Mindestbetrag Krankenkasse abrechnen</h4>
                  <v-text-field v-model="minKrankenkasse" suffix="CHF" placeholder="0.00"
                                style="width: 200px"></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-switch v-model="isVerrechenbarBewohner" :true-value="1" :false-value="0"
                            label="Nicht verrechenbare Beträge an Bewohner übertragen"></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <h4>E-Mail-Adressen für Benachrichtigungen</h4>
                  <v-combobox class="notemail" v-model="notificationEmails"
                              label=""
                              mails
                              :error=false
                              flat
                              :rules="emailRules"
                              multiple>
                    <template v-slot:selection="data">
                      <v-chip :input-value="data.selected"
                              small
                              text-color="black"
                              class="mb-2 secondary"
                              close
                              @click:close="removeEmail(data.item)">
                        <strong>{{ data.item }}</strong>&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>

                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="custom-box">
            <div class="title">
              <h2>Schnittstelle</h2>
            </div>
            <div class="content">
              <v-row>
                <v-col cols="2">
                  <h4>ERP-System</h4>
                  <v-select
                      v-model="erp"
                      :items="erpItems"
                      item-text="label"
                      item-value="key"
                      @change="updateErp"
                      :disabled="!settings"
                  />
                </v-col>
                <v-col cols="5" v-if="erp != 'none'">
                  <div class="expandable-setting-box">
                    <div class="setting">
                      <v-checkbox label="Bewohner importieren"
                                  v-model="syncBewohner"></v-checkbox>
                    </div>
                    <HeightTransition>
                      <div class="expandable-content" v-if="syncBewohner">
                        <v-data-table :headers="syncBewohnerListHeaders" :items="wohneinheiten"
                                      :hide-default-footer="true"
                                      class="custom-data-table bewohner-sync-table">
                          <template v-slot:item.id="{item}">
                            {{ item.name }} (ID: {{ item.id }})
                          </template>
                          <template v-slot:item.externalID="{item}">
                            <input type="text" v-model="item.externalID"
                                   placeholder="Nummer eingeben"
                                   @change="saveWohneinheiten()"></input>
                          </template>
                        </v-data-table>
                      </div>
                    </HeightTransition>
                  </div>
                </v-col>
                <v-col cols="5" v-if="erp != 'none'">
                  <div class="expandable-setting-box">
                    <div class="setting">
                      <v-checkbox label="Leistungen an ERP System übertragen"
                                  v-model="syncLeistungen"></v-checkbox>
                    </div>
                    <HeightTransition>
                      <div v-if="erp === 'lobos' && !isPurasdirectActive" class="px-4 pb-4">
                        allfälliger Admin-Zuschlag wird im LOBOS berechnet
                      </div>
                      <div class="expandable-content"
                           v-if="syncLeistungen && !isPurasdirectActive && erp !== 'lobos'">
                        Admin Zuschlag Abrechnung MiGeL Produkte (in %)

                        <v-text-field style="width: 150px;" placeholder="0" suffix="%"
                                      v-model="zuschlag"
                        ></v-text-field>

                      </div>

                    </HeightTransition>
                    <HeightTransition>
                      <div class="expandable-content"
                           v-if="syncLeistungen && isPurasdirectActive">
                        Leistungsnummer für Total Nebenleistungen
                        <v-text-field v-model="leistungsnummerNebenleistungen"
                                      style="width: 150px;" placeholder="Nummer eingeben"
                        ></v-text-field>
                      </div>
                    </HeightTransition>

                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isPurasdirectActive">
        <v-col cols="12">
          <div class="custom-box">
            <div class="title">
              <h2>Verwaltung Ärzte/Heimarzt</h2>
              <ArztDialog @save="addArzt($event)">
                <template #button="slotProps">
                  <div class="new-arzt-icon">
                    <v-hover v-slot="{ hover }">
                       <span
                           class="cursor-pointer text-center align-center"
                           @click="slotProps.open()">
                              <div class="block pt-0 caption text-right small">
                          Arzt erfassen
                      </div>
                        <v-img :src="require('@/assets/img/ico2023/bewohner-hinzuefuegen.svg')"
                               :contain="true" alt="" class="icon" max-height="47"></v-img>
                      </span>
                    </v-hover>
                  </div>
                </template>
              </ArztDialog>
            </div>
            <div class="content">
              <v-data-table :headers="aerzteHeaders"
                            :items="aerzte"
                            :items-per-page="10"
                            item-value="id"
                            :hide-default-footer="aerzte.length <= 10"
                            :footer-props="{
                              showFirstLastPage: true,
                              itemsPerPageText: 'Einträge pro Seite',
                              itemsPerPageAllText: 'alle',
                              pageText: ''
                            }"
                            class="custom-data-table custom-padding transparent pt-4">

                <template v-slot:item="{ item }">
                  <tr class='table-row cursor-pointer'>
                    <td>{{ item.name }}</td>
                    <td> {{
                        item.strasse + ' ' + item.hausnr
                      }}
                    </td>
                    <td>{{ item.plz }}</td>
                    <td>{{ item.ort }}</td>
                    <td>{{ item.zsr }}</td>
                    <td>{{ item.gln }}</td>
                    <td>
                      <v-icon v-if="item.default == 1">mdi-check-circle-outline
                      </v-icon>
                    </td>
                    <td class="text-right">
                      <ArztDialog :arzt='item' @save="updateArzt(item.ID, $event)"
                                  @delete="deleteArzt(item.ID)" :edit="true">
                        <template #button="slotProps">
                          <v-hover v-slot="{hover}">
                             <span
                                 class="cursor-pointer text-center align-center"
                                 @click="slotProps.open()">
                             <v-img
                                 :src="hover ? require('@/assets/img/ico/bearbeiten-hover.svg') : require('@/assets/img/ico/bearbeiten.svg')"
                                 width="18" class="icon"></v-img>
                            </span>
                          </v-hover>
                        </template>
                      </ArztDialog>

                    </td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  Es wurde kein Arzt gefunden.
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {
  wohneinheitenGetters,
  wohneinheitenActions,
  wohneinheitenMutations
} from '../components/observables/wohneinheiten'
import { mapGetters } from 'vuex'
import { aerzteActions, aerzteGetters } from '@/components/observables/aerzte'
import ArztDialog from '@/components/Systemsettings/dialogs/ArztDialog.vue'
import LogoDialog from '@/components/Systemsettings/dialogs/LogoDialog.vue'
import HeightTransition from '@/components/shared/HeightTransition.vue'
import kunde from '@/components/observables/kunde'
import { hasModuleActive } from '../composables/clientModules'

export default {
  name: 'Settings',
  components: { HeightTransition, LogoDialog, ArztDialog },
  data () {
    return {
      erpItems: [
        { key: 'none', label: 'kein' },
        { key: 'lobos', label: 'LOBOS' },
        { key: 'nexus', label: 'NEXUS' },
        { key: 'abacus', label: 'Abacus' },
      ],
      addressHeaders: [
        { text: 'Name', value: 'NameFirma', sortable: false },
        { text: 'Adress-Nummer', value: 'AdressNrADR', sortable: false },
        { text: 'Strasse', value: 'Strasse', sortable: false },
        { text: 'Haus-Nr', value: 'Hausnr', sortable: false },
        { text: 'PLZ', value: 'PLZ', sortable: false },
        { text: 'Ort', value: 'Ort', sortable: false },
        { text: 'GLN', value: 'GLN', sortable: false },
        { text: 'UID', value: 'UIS', sortable: false },
        { text: 'ZSR', value: 'ZSR', sortable: false },
        { text: 'Logo', value: 'Logo', align: 'center', sortable: false }
      ],
      syncBewohnerListHeaders: [
        { text: 'Wohneinheit ID Verbrauchsmanager', value: 'id', sortable: false },
        { text: 'Abteilung ERP System', value: 'externalID', sortable: false },
      ],
      aerzteHeaders: [
        { text: 'Name', value: 'name', align: 'start' },
        { text: 'Strasse', value: 'strasse', align: 'start' },
        { text: 'PLZ', value: 'plz', align: 'start' },
        { text: 'Ort', value: 'ort', align: 'start' },
        { text: 'ZSR', value: 'zsr', align: 'start' },
        { text: 'GLN', value: 'gln', align: 'start' },
        { text: 'Standard', value: 'default', align: 'start' },
        { text: '', value: 'action', align: 'end' },
      ],
      emailRules: [
        v => {
          if (!v || v.length === 1) {
            return true
          } else if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v[i]))) {
                return 'keine gültige E-Mail-Adresse (mindestens eine Adresse wird so nicht gespeichert)'
              }
            }
          }
          return true

        }
      ],
    }
  },

  computed: {
    kunde () {
      return kunde
    },
    ...wohneinheitenGetters,
    ...aerzteGetters,
    ...mapGetters({
      'isLoading': 'system/isLoading',
      'settings': 'benutzer/settings',
      'info': 'benutzer/info'
    }),

    isPurasdirectActive () {
      return hasModuleActive('purasdirect')
    },

    isErpLoading () {
      return this.$store.getters['system/isElementLoading']('setErp')
    },
    logoDisplayName () {
      let split = this.info.logo.split('/')
      return split[split.length - 1]
    },

    addresses: {
      get () {
        return [
          {
            NameFirma: this.info.anschrift.NameFirma,
            AdressNrADR: this.info.AdressNrADR,
            Strasse: this.info.anschrift.Strasse,
            HausNr: this.info.anschrift.HausNr,
            PLZ: this.info.anschrift.PLZ,
            Ort: this.info.anschrift.Ort,
            GLN: this.info.aph.GLN,
            UID: this.info.aph.UID,
            ZSR: this.info.aph.ZSR,
            Logo: null,
          }
        ]
      }
    },

    erp: {
      get () {
        return this.settings.erp
      },
      set (value) {
        this.$store.dispatch('benutzer/setErp', value)
      }
    },

    syncBewohner: {
      get () {
        return this.settings.syncBewohner
      },
      set (value) {
        this.$store.dispatch('benutzer/setSyncBewohner', value)
      }
    },

    notificationEmails: {
      get () {
        return this.settings.notificationEmails
      },
      set (value) {

        if (this.emailRules[0](value) === true) {
          this.$store.dispatch('benutzer/setNotificationEmails', value)
        }
      }
    },

    syncLeistungen: {
      get () {
        return this.settings.syncLeistungen
      },
      set (value) {
        this.$store.dispatch('benutzer/setSyncLeistungen', value)
      }
    },

    zuschlag: {
      get () {
        return this.settings.zuschlag
      },
      set (value) {
        this.$store.dispatch('benutzer/setZuschlag', value)
      }
    },

    leistungsnummerNebenleistungen: {
      get () {
        return this.settings.leistungsnummerNebenleistungen
      },
      set (value) {
        this.$store.dispatch('benutzer/setLeistungsnummerNebenleistungen', value)
      }
    },

    minKrankenkasse: {
      get () {
        return this.settings.minKrankenkasse
      },
      set (value) {
        this.$store.dispatch('benutzer/setMinKrankenkasse', value)
      }
    },

    isVerrechenbarBewohner: {
      get () {
        return this.settings.isVerrechenbarBewohner
      },
      set (value) {
        this.$store.dispatch('benutzer/setIsVerrechenbarBewohner', value)
      }
    },

    isPurasDirectLive: {
      get () {
        return this.settings.isPurasDirectLive
      },
      set (value) {
        this.$store.dispatch('benutzer/setIsPurasDirectLive', value)
      }
    },

    inkosync: {
      get () {
        return this.settings.inkosync
      },
      set (value) {
        this.settings.inkosync = value
        this.changeInkosync()
      }
    },

  },

  mounted () {
    this.$store.dispatch('benutzer/loadSettings')
  },

  methods: {
    ...wohneinheitenActions,
    ...wohneinheitenMutations,
    ...aerzteActions,

    removeEmail (item) {
      if (this.notificationEmails.indexOf(item) !== -1) {
        this.notificationEmails.splice(this.notificationEmails.indexOf(item), 1)
        this.notificationEmails = [...this.notificationEmails]
      } else {
        this.notificationEmails = [...this.notificationEmails]
      }
    },

    updateErp (e) {
      this.syncBewohner = 0
      this.syncLeistungen = 0
      if (!this.isPurasdirectActive) {
        this.zuschlag = 0
      }
    },

    changeInkosync () {
      if (this.settings.inkosync === 1) {
        this.$confirm('Wenn die Synchronisation aktiviert wird, werden die Einträge des aktuellen Monats der Inko-Produkte zurückgesetzt. Sollten Intervalle definiert worden sein, werden die Werte automatisch übernommen.', {
          title: 'Achtung',
          buttonTrueText: 'Synchronisation aktivieren',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {
            this.$store.dispatch('benutzer/setInkosync', this.settings.inkosync)
          } else {
            this.settings.inkosync = 0
            this.$store.dispatch('benutzer/setInkosync', 0)
          }
        })
      } else {
        this.$store.dispatch('benutzer/setInkosync', this.settings.inkosync)
      }
    }
  },

  async created () {
    this.fetchWohneinheiten()
    this.fetchAerzte()
  }
}
</script>
<style lang="scss">

#superadmin {
  ::v-deep {
    .v-input--switch {
      label {
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 2px;
        font-size: 16px;
      }
    }
  }

  .bewohner-sync-table {
    input {
      border: 1px solid black;
      width: 100%;
      border-radius: 10px;
      padding: 2px 8px 0px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .expandable-setting-box {
    margin-top: 38px;
  }

  .new-arzt-icon {
    position: absolute;
    top: 0;
    right: 0;

    > span {
      display: block;
      width: auto;
    }

    .caption {
      display: block;
      float: left;
      font-family: "Avenir LT Std Book";
      font-weight: bold;
      max-width: 60px;
      line-height: 1.2em;
      margin-top: 8px;
    }

    .icon {
      display: block;
      float: left;
      margin-left: 10px;
    }
  }

  .logo-filename {
    text-decoration: underline;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
    display: inline-block;
    width: 90px;
    height: auto;
    line-height: 1;
    top: 5px;
    position: relative;
  }

  .v-data-table__wrapper {
    overflow: hidden;
  }
}
</style>
